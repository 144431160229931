<script lang="ts" setup>
  const { t } = useI18n()
  const items = [
    {
      icon: '/images/icons/vision.svg',
      label: t('vision:card:one:title'),
      content: t('vision:card:one:text'),
    },
    {
      icon: '/images/icons/mission.svg',
      label: t('vision:card:two:title'),
      content: t('vision:card:two:text'),
    },
  ]

  const videoRef = ref()
  const videoPaused = ref(!Boolean(videoRef.value?.paused))

  const videoClicked = function () {
    if (videoPaused.value) {
      videoRef.value.play()
      videoPaused.value = false
    } else {
      videoRef.value.pause()
      videoPaused.value = true
    }
  }
</script>
<template>
  <section class="vision">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="vision-content">
            <h2 class="vision-title" v-html="t('vision:title')"></h2>
            <p class="vision-text" v-html="t('vision:text')"></p>
          </div>
          <Accordion :items="items" :only-one="true">
            <template #header="{ item }">
              <figure class="vision__card-figure">
                <img class="vision__card-img" :src="item.icon" alt="" loading="lazy" />
              </figure>
              <h3 class="vision__card-title">{{ item.label }}</h3>
            </template>
            <template v-slot="{ item }">
              <div class="vision__card-content">
                <p class="vision__card-text">{{ item.content }}</p>
              </div>
            </template>
          </Accordion>
        </div>
        <div class="col-lg-6">
          <figure class="vision-figure">
            <div class="vision-video-wrapper" @click="videoClicked">
              <video ref="videoRef" class="vision-video" onloadedmetadata="this.muted = true" loop playsinline muted>
                <source src="/videos/vision.mp4" type="video/mp4" />
              </video>
              <span v-if="videoPaused" class="vision-video-camera">
                <img src="/images/icons/camera.svg" alt="camera icon" loading="lazy" />
              </span>
            </div>
            <img class="vision-img" src="/images/pages/index/vision.webp" alt="vision" loading="lazy" />
            <figcaption class="vision-figcaption" v-html="t('vision:figcaption')"></figcaption>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .vision {
    padding: 4rem 0;
    .accordion-header {
      padding-left: 0;
      padding-right: 0;
    }
    &-content {
      margin-bottom: 3rem;
    }
    &-header {
      margin-bottom: 4rem;
    }
    &-subtitle {
      color: #fd9396;
      font-size: 24px;
      line-height: 1.1;
      margin-bottom: 0.5rem;
    }
    &-title {
      color: #263a75;
      font-family: 'Righteous', 'Roboto';
      font-size: 42px;
      font-weight: 500;
      line-height: 1.14;
      margin-bottom: 1rem;
    }
    &-text {
      color: #263a75;
      font-size: 17px;
      line-height: 28px;
    }
    &-figure {
      position: relative;
      display: flex;
      flex-direction: column;
    }
    &-video {
      width: 100%;
      max-width: 400px;
      aspect-ratio: 1.1;
      border-radius: 14px;
      object-fit: cover;
      cursor: pointer;
      z-index: 1;
      &-wrapper {
        position: relative;
        margin-left: auto;
      }
      @keyframes cameraCircle {
        from {
          width: calc(100% + 24px);
          height: calc(100% + 24px);
        }
        to {
          width: calc(100% + 8px);
          height: calc(100% + 8px);
        }
      }
      @keyframes cameraCircle2 {
        from {
          width: calc(100% + 48px);
          height: calc(100% + 48px);
        }
        to {
          width: calc(100% + 36px);
          height: calc(100% + 36px);
        }
      }
      &-camera {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #fd9396;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 3;
        &::before {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: calc(100% + 24px);
          height: calc(100% + 24px);
          border-radius: 50%;
          background-color: rgba(253, 147, 150, 0.27);
          transform: translate(-50%, -50%);
          animation: cameraCircle 2s infinite alternate;
          z-index: -1;
        }
        &::after {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          width: calc(100% + 48px);
          height: calc(100% + 48px);
          border-radius: 50%;
          background-color: rgba(253, 147, 150, 0.27);
          transform: translate(-50%, -50%);
          animation: cameraCircle2 2.5s infinite alternate;
          z-index: -2;
        }
      }
    }
    &-img {
      width: 100%;
      max-width: 50%;
      margin-top: -20%;
    }
    &-figcaption {
      position: absolute;
      top: 61%;
      left: 58%;
      width: 36%;
      color: #263a75;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.1;
      text-align: center;
      padding: 1.5rem 2rem;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: 3px solid #fd9396;
      background-color: #fff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      z-index: 2;
    }
    &__card {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      &-figure {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 63px;
        height: 52px;
        padding: 0.5rem;
        margin-right: 1.5rem;
        border-radius: 13px;
        background-color: #fec9cb;
      }
      &-content {
        padding: 1.5rem 1.5rem 2rem;
      }
      &-title {
        color: #263a75;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.67;
      }
      &-text {
        color: #263a75;
        font-size: 16px;
        line-height: 1.67;
      }
      &-img {
        height: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 991.98px) {
    .vision {
      padding: 2rem 0;
      &-figure {
        margin-top: 2rem;
      }
      &-content {
        margin-bottom: 1.5rem;
      }
      &__card {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
      }
    }
  }
</style>
